import React from "react";
import imageE from "../assets/images/plat1.jpg";
import imageH from "../assets/images/habillement.jpg";
import imageN from "../assets/images/technologie.jpg";

function News() {
    return (
        <div className="container mx-auto" id="Menu">
            <h2 className="pt-8 pb-4 md:pt-16 md:pb-12 text-3xl font-extrabold ml-8 sm:ml-0 mx-auto sm:text-center">Nos services</h2>
            <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <div className="flex shadow-md sm:shadow-2xl flex-col">
                    <img src={imageE} className="rounded-lg sm:rounded-none sm:h-56 bg-cover sm:w-full" />
                    <h1 className="text-xl sm:text-xl pt md:px-2 pt-6">Les plats de chez nous</h1>
                    <p className="leading-normal text-sm w-9/12 px-2 py-2"> Découvrez les meilleurs des plats affricain telque Basko, WataFoufou...</p>
                </div>
                <div className="flex shadow-md sm:shadow-2xl flex-col">
                    <img src={imageH} className="sm:h-56 bg-cover sm:w-full" />
                    <h1 className="text-xl sm:text-xl pt md:px-2 pt-6">Habillements</h1>
                    <p className="leading-normal text-sm w-9/12 px-2 py-2"> Les produits de mode de toute catégories sont disponible faites seulement votre choix...</p>
                </div>
                <div className="flex shadow-md sm:shadow-2xl flex-col">
                    <img src={imageN} className="sm:h-56 bg-cover sm:w-full" />
                    <h1 className="text-xl sm:text-xl pt md:px-2 pt-6">Nouvelles technologies</h1>
                    <p className="leading-normal text-sm w-9/12 px-2 py-2">Les meilleurs appareils électroniques à des prix promotionnel.</p>
                </div>
                <div className="flex shadow-md sm:shadow-2xl flex-col">
                    <img src={imageE} className="sm:h-56 bg-cover sm:w-full" />
                    <h1 className="text-xl sm:text-xl pt md:px-2 pt-6">Autres services</h1>
                    <p className="leading-normal text-sm w-9/12 px-2 py-2">Decouvrez les autres services de Mape-Shop ici...</p>
                </div>
            </div>
        </div>
    )
}

export default News;