import React from "react";

function About(){
    return(
        <div className="container mx-auto py-10 md:mt-6 md:mb-12 leading-tight flex items-start justify-start flex-col sm:items-center sm:pb-2 sm:pt-10 sm:justify-center" id="A%20propos">
            <h2 className="w-11/12 mx-auto text-3xl mb-2 lg:text-center font-extrabold">A propos de nous</h2>
            <p className="leading-normal text-sm mx-auto my-0 w-11/12 lg:w-6/12 lg:text-center">Votre partenaire de tous les jours qui vous propose un large choix de produits parmi les plus grandes marques aux meilleurs prix, d'une qualité exceptionnelle et contrôlée.</p>
        </div>
    )
}

export default About;