import React from "react";
import alimPic from "../assets/images/alim.jpg";

function Banner() {
    return (
        <div className="shadow-md" >
            <div style={{ height: '60vh', backgroundImage: "url(" + alimPic +")", backgroundColor: "rgba(0,0,0,0.3)"}} className="bg-opacity-50 bg-cover flex items-center justify-start" >
                <p className="ml-8 bg text-gray-300 text-3xl md:text-5xl md:w-4/12 font-bold w-64">Plateforme de vente en ligne à Maroua.</p>
            </div>
            <div className="mt-3 shadow-lg">
                <div className="container mx-auto flex flex-col py-3 items-center justify-center md:text-center md:flex-row sm:flex-row lg:flex-row">
                    <p className="text-sm text-center py-2 w-48 md:w-2/3 sm:w-2/3 lg:w-2/3"> Libre et gratuit, téléchargez l'application MAPE v1.0</p>
                    <button className="bg-blue-500 hover:bg-blue-900 rounded-md w-11/12 mt-2 py-2 md:mt-0 sm:mt-0 lg:mt-0 md:w-1/3 sm:w-1/3 lg:w-1/3"><a href="https://mape-shop.com/mape.apk" className="text-white text-sm">Télécharger MAPE</a></button>

                </div>
            </div>
        </div>
    )
}

export default Banner;