import React from "react";
import Logo from "../assets/images/logo.png";

function Footer() {

    const com = ["A propos", "FAQ", "Blog"];
    const navCom = com.map(
        co => {
            return (<li><a href={"#" + co} className="text-sm">{co}</a></li>)
        }
    )


    const joins = ["Collabrations", "Services", "Partenariats"];
    const navJoin = joins.map(
        jo => {
            return (<li><a href={"#" + jo} className="text-sm">{jo}</a></li>)
        }
    )


    const locations = ["Maroua", "Garoua", "Ngaoundere"];
    const navLoc = locations.map(
        lo => {
            return (<li><a href={"#" + lo} className="text-sm">{lo}</a></li>)
        }
    )

    return (
        <div className="container ml-8 lg:ml-0 text-left mx-auto mt-6">
        <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div className="flex justify-start items-start md:items-center flex-col">
                <img src={Logo} className="h-20 w-20" />
                <p className="mt-2 text-sm">mape-shop@mape-shop.com</p>
            </div>
            <div className className="leading-normal">
                <h1 className="text-xl font-semibold">Compagnie</h1>
                <ul>{navCom}</ul>
            </div>

            <div className className="leading-normal">
                <h1 className="text-xl font-semibold">Joindre Mape</h1>
                <ul>{navJoin}</ul>
            </div>
            <div className className="leading-normal">
                <h1 className="text-xl font-semibold">Localisation</h1>
                <ul>{navLoc}</ul>
            </div>
            </div>
            <div className="my-8 sm:mt-24 ml-4 sm:ml-0 md:text-center grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mx-auto ">
                <p className="text-sm">Vente en ligne avec Mape</p>
                <p className="text-sm">Politique d'utilisation</p>
                <p className="text-sm">Terme d'usage</p>
                <p className="text-sm">© 2020 MANLAB</p>
            </div>
        </div>
    )
}

export default Footer;