import React from "react";

function Sondage() {
    return (
        <div className="container mx-auto">
            <form className="bg-white pt-2 pb-16 mx-auto md:mt-6 md:mb-12 leading-tight flex items-start justify-start flex-col sm:items-center sm:py-12 sm:justify-center">
               
                    
                        <input placeholder="email" className="text-sm shadow appearance-none mb-2 p-2 w-full sm:w-1/3 border rounded" />
                        <input placeholder="telephone" className="text-sm shadow appearance-none w-full  mb-2 p-2 sm:w-1/3 border rounded " />
                        <input placeholder="Nom" className="text-sm shadow appearance-none border mb-2 p-2 w-full sm:w-1/3 rounded " />
                        <input type="text" placeholder="Entrez un texte ici.." className="text-sm h-24 mb-2 p-2 w-full sm:w-1/3 shadow appearance-none border" />
                        <button className="h-10 text-white bg-blue-500 hover:bg-blue-900  p-2 w-full sm:w-1/3 rounded text-sm">valider</button>
                
    
            </form>
        </div>
    )
}

export default Sondage;