import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import About from "../components/About";
import News from "../components/News";
import Team from "../components/Teams";
import Footer from "../components/Footer";
import Sondage from "../components/Sondage";

class Index extends Component {
    render() {
        return (
            <div className="site">
                <Header />
                <Banner />
                <News />
                <Team />
                <About />
                <Sondage />
                <Footer />
            </div>
        )
    }
}
export default Index;