import React from "react";
import logoImg from "../assets/images/logo.png";

function Header(){
    const items = ["Menu", "Equipes", "A propos"]; 
    const listItems = items.map(item => {
        return(
            <li className="inline-block mr-5"><a href={"#"+item} className="hover:text-blue-700 hover:scale-150 hover:skew-x-6 hover:font-extrabold">{item}</a></li>
        )
    })
    return(
        <div className="shadow">
        <header className="container flex py-3 mx-auto justify-between flex-row items-center">
            <a href="#" className="text-red ml-4 flex items-center justify-center flex-row"><img style={{ width: '30px', marginRight: '10px'}} src={logoImg} /> MAPE</a>
            <ul className="hidden md:inline sm:inline lg:inline xl:inline">{listItems}</ul>
            <p className="mr-5 md:hidden"></p>
        </header>
        </div>
    )
}

export default Header;