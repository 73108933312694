import React from "react";
import imageE from "../assets/images/plat1.jpg";
import imageA from "../assets/images/asam.jpg";

function Team() {
    return (
        <div className="container mx-auto" id="Equipes">

            <h2 className="pt-10 pb-5 md:pt-24 md:pb-12 text-3xl font-extrabold ml-8 sm:ml-0 mx-auto sm:text-center">Equipes</h2>

            <div className="mr-3 px-10 py-6 grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-2">

                <div className="flex mb-3 md:mb-0 flex-col md:justify-center md:items-center md:text-center leading-normal">
                    <div className="object-cover w-32 rounded-full m:w-40 h-32 mr-5" style={{background: "url("+imageE+")"}}></div>
                    <div className="flex flex-col mt-2 md:mt-4">
                    <h2 className="text-lg">Mana Tchindebe Etienne</h2>
                    <p className="text-sm text-gray-800 mt-2">Lead-developer, designer & full stack</p>
                    </div>
                </div>

                <div className="flex mb-3 md:mb-0 flex-col md:justify-center md:items-center md:text-center leading-normal">
                    <div className="object-cover w-32 rounded-full m:w-40 h-32 mr-5" style={{background: "url("+imageE+")"}}></div>
                    <div className="flex flex-col mt-2 md:mt-4">
                    <h2 className="text-lg">Sali Emmanuel</h2>
                    <p className="text-sm text-gray-800 mt-2">Mobile Developer</p>
                    </div>
                </div>
                <div className="flex mb-3 md:mb-0 flex-col md:justify-center md:items-center md:text-center leading-normal">
                    <div className="object-cover w-32 rounded-full m:w-40 h-32 mr-5" style={{background: "url("+imageE+")"}}></div>
                    <div className="flex flex-col mt-2 md:mt-4">
                    <h2 className="text-lg">Pierre Ngounou</h2>
                    <p className="text-sm text-gray-800 mt-2">Co-concepteur</p>
                    </div>
                </div>
                <div className="flex mb-3 md:mb-0 flex-col md:justify-center md:items-center md:text-center leading-normal">
                    <img src={imageA} className="w-32 object-cover rounded-full m:w-40 h-32 mr-5" />
                    <div className="flex flex-col mt-2 md:mt-4">
                    <h2 className="text-lg">Abba Sali</h2>
                    <p className="text-sm text-gray-800 mt-2">Technical support</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team;